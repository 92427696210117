@font-face {
  font-family: nexa;
  src: url("./fonts/nexa\ slab\ fonts/Fontfabric - NexaSlab-Bold.otf");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.curved__footer__lines {
  /* border: 1px solid red; */
  height: 100%;
  object-fit: cover;
}
.selected-flag {
  /* background-color: white !important; */
  border: 1px solid #c4c4c4;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  /* /* border-left: none; */
  border-top: none !important;
  border-bottom: none !important;
}
.no_scroll_bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no_scroll_bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* cards animations */
:root {
  --card-height: 40vw;
  --card-margin: 4vw;
  --card-top-offset: 1em;
  --numcards: 4;
  --outline-width: 0px;
}

#card_1 {
  --index: 1;
  /* width: 100% !important; */
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

#card_5 {
  --index: 5;
}

.card {
  position: sticky;
  top: 100px;
  padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: works) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets:
			/* Start when the start edge touches the top of the scrollport */ selector(
          #cards
        )
        start 1,
      /* End when the start edge touches the start of the scrollport */
        selector(#cards) start 0;
    start: selector(#cards) start 1; /* Start when the start edge touches the top of the scrollport */
    end: selector(#cards) start 0; /* End when the start edge touches the start of the scrollport */
    time-range: 4s;
  }

  .card {
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
  }

  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;
    /* width: 100%; */

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}

.card__content {
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
}

.card {
  --index0: calc(var(--index) - 1); /* 0-based index */
  --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
  --reverse-index0: calc(var(--reverse-index) - 1); /* 0-based reverse index */
}

@keyframes scale {
  to {
    transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
  }
}

#cards {
  --numcards: 5;
  view-timeline-name: --cards-element-scrolls-in-body;
}

.card__content {
  --start-range: calc(var(--index0) / var(--numcards) * 100%);
  --end-range: calc((var(--index)) / var(--numcards) * 100%);

  animation: linear scale forwards;
  animation-timeline: --cards-element-scrolls-in-body;
  animation-range: exit-crossing var(--start-range) exit-crossing
    var(--end-range);
}

@media only screen and (min-width: 900px) {
  /* scroll section animations */
  .sticky_parent {
    /* border: 1px solid red; */
    height: 200vh;
    background: none;
    transition: background 500ms ease-in-out 200ms;
  }

  .sticky {
    overflow: hidden;
    position: sticky;
    top: 0px;
    height: 100vh;
    /* border: 4px solid blue; */
    /* padding-bottom: 100px; */
  }

  .scroll_section {
    will-change: transform;
    /* background-color: none; */
    /* width: 500vw; */
  }
}

/* phone input form */
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100%;
  outline: none;
}
