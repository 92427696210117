:root {
  --primary-color: #0b1e5b;
  /* Replace with your primary color code */
  --secondary-color: #ff6b6b;
  /* Replace with your secondary color code */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f7f8f2 !important;
  font-family: "Inter", sans-serif !important;
}

button {
  border-radius: 8px !important;
}

.Primary-button {
  background-color: #0b1e5b !important;
  border-radius: 8px !important;
}

.purpleOutlinedButton {
  border-color: #8e75f0 !important;
  color: #8e75f0 !important;
}

img.MainBackgroundImage {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
}

div#Categories {
  text-align: left;
}

.css-11lq3yg-MuiGrid-root {
  justify-content: space-between;
  align-items: center;
}

a.MuiTypography-root.MuiTypography-body2.MuiLink-root.MuiLink-underlineAlways.css-101ca9i-MuiTypography-root-MuiLink-root {
  color: #333333;
}

.FileUpload {
  background: #fff !important;
  box-shadow: 0px 0px 0px !important;
  border: 1px dashed #c4c4c4 !important;
  color: #333 !important;
  height: auto;
  margin-top: 10px !important;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-bottom: 15px !important;
}

form.AccountBox .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root,
.css-1iledgx-MuiFormControl-root {
  background: #fff;
}

img.img-fluid.MainLogo {
  margin: 20px 10px;
  width: 100%;
  max-width: 110px;
}

header {
  background: transparent !important;
  color: #333 !important;
  box-shadow: none !important;
}

.overViewPanel {
  padding: 10px 20px;
  text-align: left;
  max-height: 180px;
  height: 160px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 0px #000 !important;
  border-radius: 15px;
}

#OverViewPanel01 h4 {
  color: #8e75f0;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#OverViewPanel02 h4 {
  color: #8caf31;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#OverViewPanel03 h4 {
  color: #0b1e5b;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#OverViewPanel04 h4 {
  color: #4267b2;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}
#DashOverViewPanel01 h4 {
  color: #0b1e5b;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#DashOverViewPanel02 h4 {
  color: #8e75f0;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#DashOverViewPanel03 h4 {
  color: #8caf31;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

#DashOverViewPanel04 h4 {
  color: #4267b2;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
}

.ConnectWalletBTN {
  background: #0b1e5b !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  white-space: nowrap;
}

.react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
  /* height: 100%; */
  padding: 10px;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 9.5px 0px;
}

.PhoneNumber {
  position: relative;
  background: transparent;
  margin: 10px 0px;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}

.react-tel-input .form-control {
  padding-left: 0 !important;
  border: 0px solid #cacaca !important;
}

.react-tel-input .flag-dropdown {
  border: 0px solid #cacaca !important;
}

.ConnectWalletBTN {
  margin-top: 8px !important;
}

a.sideBarLink {
  text-decoration: none;
  color: #757575;
  width: 100%;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px 5px !important;
}

.MainBoxComponent.MuiBox-root.css-0 {
  padding: 20px;
  background: #fff;
  border-radius: 35px 0px 0px 0px;
}

.ProductPanelBox {
  text-align: left;
  padding: 5px 20px;
  background: #f7f8f2;
  border-radius: 8px;
  margin: 15px 0px;
}

.ProductTotal {
  padding: 15px !important;
  background: #8e75f0 !important;
  color: #fff !important;
}

.light-grey01 {
  background: #f5f5f5 !important;
  color: #757575 !important;
  margin: 0px 5px !important;
  box-shadow: 0px 0px 0px #000 !important;
}

.light-grey02 {
  border: 1px solid #c2c2c2 !important;
  color: #c2c2c2 !important;
  margin: 0px 5px !important;
  background: transparent !important;
}

.AccordionHeader {
  border-bottom: 1px solid #ddd !important;
  padding: 0px !important;
  margin: 0px 20px !important;
}

.GreenBtn {
  background: #dcfee8;
  color: #1ed760;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 100px;
  font-size: 0.9rem;
}

a.sideBarLink .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  min-width: 30px !important;
}

a.sideBarLink .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  min-width: 30px !important;
}

a.sideBarLink svg {
  font-size: 1.2rem;
}

.productHistory {
  box-shadow: 0px 0px 0px !important;
  border: 1px solid #c2c2c2;
  padding: 10px;
}

.productHistory h6 {
  color: #757575;
}

.StepForm.MuiBox-root.css-a6rva7 {
  padding: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  margin: 30px 0px;
}

.ProductImg.MuiBox-root.css-0 img {
  width: 100%;
  margin-top: 10px;
  max-height: 132px;
}

.ProductImg.MuiBox-root.css-0 {
  position: relative;
}

svg.closeIcon {
  position: absolute;
  right: 0px;
  color: #d32f2f;
  font-size: 1.5rem;
  background: #fff;
  border-radius: 100px;
  cursor: pointer;
}

div#demo-simple-select-unit {
  padding: 17px;
}

button.purpleColor {
  padding: 0;
  margin: 0;
  text-align: right;
  width: auto;
  display: flex;
  justify-content: end;
  font-size: 12px;
  align-items: center;
  color: #8e75f0;
  width: 100%;
  position: relative;
  top: 25px;
}

button.purpleColor:hover {
  background: transparent;
}

div#demo-simple-select {
  padding: 16px;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:invalid {
  box-shadow: none;
  padding: 10px;
}

.MainBoxComponent .ProductInfo .MuiPaper-root.MuiPaper-elevation {
  border-radius: 8px !important;
  border: 1px solid #e0e0e0 !important;
}

.ProductSearchBox {
  background: #fff;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 10px !important;
  margin-top: 5px !important;
}

.ProductInfos {
  background: #f7f8f2;
  padding: 30px 30px;
  border-radius: 8px;
  margin: 10px 0px;
}

.ProductDis {
  background: #f5f5f5 !important;
  padding: 30px 30px;
  border-radius: 8px;
  margin: 40px 0px;
}

.InfoDoc {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.UploadPdf.MuiBox-root.css-0 {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.Checkbox.MuiBox-root.css-0 {
  max-width: 100%;
  width: 70%;
}

.Checkbox .MuiFormControlLabel-label {
  white-space: normal !important;
  text-align: left !important;
}

.CheckPointModal {
  background: #fff;
  padding: 50px;
  border-radius: 10px;
}

.statusCss {
  background: #dcfee8;
  text-align: center;
  padding: 0px;
  color: #1ed760;
  padding: 5px;
  border-radius: 50px;
}

.statusCssGrey {
  background: #f5f5f5 !important;
  text-align: center;
  padding: 0px;
  color: #757575 !important;
  padding: 5px;
  border-radius: 50px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
}

.css-g11nk3-MuiTypography-root {
  font-size: 1.7rem !important;
}

.TextField {
  text-align: left;
}

.TextField label.TextLabel {
  font-size: 14px;
}

.MainTableCss {
  border: 1px solid #e0e0e0;
  margin: 0;
  border-radius: 15px !important;
  padding: 0px 20px;
}

Button.LandingBtnHoverNav {
  background: #0b1e5b;
  box-shadow: 0px 0px 0px #000;
  color: #eff1e6;
  /* margin: 20px 10px 0px 0px; */
}

Button.LandingBtnHoverNav:hover {
  background: #0b1e5b;
  box-shadow: 0px 0px 0px #000;
  color: #eff1e6;
  /* margin: 20px 10px 0px 0px; */
}

button.LandingBtnHover {
  background: #eff1e6;
  box-shadow: 0px 0px 0px #000;
  color: #0b1e5b;
  /* margin: 20px 10px 0px 0px; */
}

button.LandingBtnHover:hover {
  background: #0b1e5b;
  box-shadow: 0px 0px 0px #000;
  color: #eff1e6;
  /* margin: 20px 10px 0px 0px; */
}

button.TextBtn {
  color: #0b1e5b;
}

button.TextBtn2 {
  color: #0b1e5b;
}

.dragSliderItem {
  background: transparent !important;
  text-align: center;
  width: 261px !important;
  padding: 52px 68px;
}

.dragSlider {
  display: flex;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 50px;
  width: 100%;
  /* overflow-x: auto; */
  /* Use 'auto' to enable horizontal scrolling */
  flex-direction: row;
  text-align: center;
}

.dragSlider img {
  border: 1px solid #adadad;
  border-radius: 10px;
  padding: 10px;
  /* width: 100%; */
  width: 250px;
}

img.sliderimg {
  width: 100%;
}

img.contactusImg {
  width: 100%;
}

img.footerLogo {
  width: 110px;
}

img.footerLogo2 {
  width: 100%;
  object-fit: cover;
}

.socialMediaIcon svg {
  font-size: 24px;
  padding: 6px;
  border: 1px solid #000;
  border-radius: 100%;
  margin: 0px 10px;
}

.FooterLink {
  display: flex;
  /* justify-content: start; */
  justify-content: space-between;
  align-items: center;
  /* gap: 80px; */
}

.FooterLink a {
  /* border: 1px solid yellow; */
  text-decoration: none;
  /* font-size: 24px; */
  font-weight: 500;
  line-height: 2;
}

.NavLink {
  text-decoration: none;
  color: #000;
  margin: 0px 50px;
  font-size: 14px;
}

.header__navlink {
  text-decoration: none;
  color: #000;
  /* margin: 0px 50px; */
  font-size: 14px;
}

.mobileLogo {
  width: 120px;
}

img.ProductImg {
  width: 100%;
}

.MobileNumber .PhoneNumber .react-tel-input input.form-control {
  background: transparent;
}

.MobileNumber .PhoneNumber .react-tel-input {
  background: transparent;
}

.MobileNumber .PhoneNumber {
  margin-top: 0px;
  margin-bottom: 10px;
}

.dark-background {
  background-color: #000;
  /* Set your dark background color */
  color: #fff;
  /* Set your dark text color */
}

.light-background {
  background-color: #fff;
  /* Set your light background color */
  color: #000;
  /* Set your light text color */
}

.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee-text {
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

.WaterBottleImg {
  width: 100%;
}

.verifyicon {
  color: #1ed760;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.productnowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productImage {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 0px 5px;
}

.producthistory {
  display: flex;
  justify-content: center;
  align-items: center;
}

img.mobilehistory {
  width: 85%;
  margin: 0px 10px 0px 0px;
}

.ProductInfo.MuiBox-root.css-0 {
  background: #f7f8f2;
  padding: 10px 20px;
  border-radius: 8px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.bigimg {
  width: 100%;
  background-color: #757575;
  border-radius: 25px;
}

.productHistory {
  text-align: left;
}

@media (max-width: 1024px) {
  .NavLink {
    margin: 0px 20px; /* Adjust margin for screens with a maximum width of 768px */
  }
  .Button.LandingBtnHoverNav {
    font-size: 9px;
  }
  button.TextBtn {
    font-size: 10px;
  }

  button.TextBtn2 {
    font-size: 14px;
  }

  #OverViewPanel01 h4 {
    font-size: 12px;
  }

  #OverViewPanel02 h4 {
    font-size: 12px;
  }

  #OverViewPanel03 h4 {
    font-size: 12px;
  }

  #OverViewPanel04 h4 {
    font-size: 11px;
  }
  #DashOverViewPanel01 h4 {
    font-size: 12px;
  }

  #DashOverViewPanel02 h4 {
    font-size: 12px;
  }

  #DashOverViewPanel03 h4 {
    font-size: 12px;
  }

  #DashOverViewPanel04 h4 {
    font-size: 11px;
  }
  .Primary-button {
    font-size: 9px !important;
  }
  button.LandingBtnHover {
    width: 40%;
  }
  .css-19elb0v-MuiTypography-root {
    font-size: 2.8rem;
    width: 100%;
  }
  button.LandingBtnHover {
    width: 100%;
  }
  .PremiumCard {
    padding: 10px;
  }
  .customHeading {
    margin-bottom: 10px;
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .marquee-text {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}
